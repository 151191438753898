<template>
  <page-template
    :img="{
      src: require('@/assets/rekrutacja-1050x630.jpg'),
      lazy: require('@/assets/rekrutacja-105x63.jpg'),
    }"
    title="Rekrutacja"
  >
  <p>
    <b>Do naszego przedszkola w pierwszej kolejności przyjmujemy dzieci, które w danym roku kalendarzowym kończą 3 lata.</b>
  </p>
  <p>
    Pierwszeństwo ma rodzeństwo dzieci aktualnie uczęszczających do Perełki oraz dzieci z placówek Montessori.
  </p>
  <p>
    Jeśli są Państwo zainteresowani zapisaniem dziecka na listę kandydatów na rok szkolny 2025/26 (dzieci z rocznika 2022) oraz 2026/27 (dzieci z rocznika 2023) <b>prosimy wyłącznie o kontakt mailowy na adres: biuro@perelkawroclaw.pl</b>. Wpisanie na listę kandydatów na dany rok szkolny nie oznacza zapisania dziecka do przedszkola. Przed rozpoczęciem rekrutacji na dany rok szkolny (luty) skontaktujemy się z Państwem, prześlemy ankietę rekrutacyjną i w kolejnym etapie zaprosimy na indywidualne spotkanie. </p>
  <p>
    Na rok szkolny 2024/25 (dzieci z rocznika 2021) – możliwe jest jedynie wpisanie się na listę rezerwową (prosimy o kontakt drogą mailową: biuro@perelkawroclaw.pl) 
  </p>
  </page-template>
</template>
<script>
import PageTemplate from '../components/PageTemplate.vue';
export default {
  components: { PageTemplate }
}
</script>
